import React from 'react'
import AdminPage from 'src/components/Page/Author/Admin'



const AdminPageContainer = ({ location }) => {
    return <AdminPage location={location} />
}

export default AdminPageContainer

